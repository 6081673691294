var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BAlert', {
    staticClass: "px-1 py-50",
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_c('div', [_vm._v(_vm._s(_vm.$t('ticket.ticketAlertWarning.1')))]), _c('div', [_vm._v(_vm._s(_vm.$t('ticket.ticketAlertWarning.2')))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }